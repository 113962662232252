<template>
    <section>
      <custom-breadcrumb
        :pageTitle="$t('Virtual Catalog')+' - '+$t('Contents') "
        :breadcrumb="[]"
      ></custom-breadcrumb>

      <b-row v-if="loading">
        <b-col cols="12"  >
          <div class="text-center">
            <b-spinner label="Loading..." />
          </div>
        </b-col>
      </b-row>
      <div v-else class="ecommerce-application">
        <b-row >   
          <b-col cols="3" class="d-lg-block d-none">
            <left-filter-sidebar 
              @contents-hierarchy-selected="selectedContentsHierarchy"
              @search="searchContents"
              @template-changed="handleTemplateChange"
              :contentsHierarchy="$store.getters['learningPaths/getContentsHierarchy']"
              :loading="loading"
              :filterOptions="$store.getters['learningPaths/getContentFilterOptions']"/>
          </b-col>
          <b-col>
            <contents-selector-container 
              :loading="loading_contents"
              :isReport="false"
              :isCatalog="true"
              :searchword.sync="searchword"
              :contentsHierarchy="$store.getters['learningPaths/getContentsHierarchy']"
              :filterOptions="$store.getters['learningPaths/getContentFilterOptions']"
              :contents="$store.getters['learningPaths/getFilteredContents']"
              :route_contents="$store.getters['learningPaths/getRoute'].contents"
              :sortedContents="sortedContents"
              :showSearchbar.sync="showSearchbar"
              @search="searchContents"
              @contents-hierarchy-selected="selectedContentsHierarchy"/>
          </b-col>
        </b-row> 
      </div>
    </section>
</template>

<script>
import LeftFilterSidebar from'@/views/learning-paths/components/LeftFilterSidebar.vue'
import ContentsSelectorContainer from '@/views/learning-paths/components/ContentsSelectorContainer.vue'
import { fetchContentSearch, getCreateLearningPathInitialData } from '@/api/learningpaths'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'

import {   
  BSpinner, BRow, BCol 
} from 'bootstrap-vue';
export default {
    components:{
        BSpinner, BRow, BCol,
        LeftFilterSidebar,
        ContentsSelectorContainer,
        CustomBreadcrumb
    },
    data(){
        return {
          searchword:'',
          loading: false,
          loading_contents:false,
          sortedContents:[],
          showSearchbar: true,
        }
    },
    created(){
      this.getData()
    },
    computed:{
      lang(){
        return this.$store.getters['user/getLang']
      }
    },
    watch:{
      lang(newLang, oldLang){  /* lanzar la llamada cada vez que cambie el idioma del usuario! */
        this.getData()
      }
    },
    methods:{
      async getData(){
        this.loading = true
        let data = {
          'user_id':  this.$store.getters['user/getUserData'].id,
          'token': this.$store.getters['user/getUserData'].sso,
        }
        await this.$http.post(getCreateLearningPathInitialData, data).then( response => {
          // console.log("getCreateLearningPathInitialData", response)
          if(response.data.status == 200){
            this.$store.commit('learningPaths/setContentFilterOptions', response.data.filterOptions)   
            this.$store.commit('learningPaths/setContentsHierarchy', response.data.contents_hierarchy)   
            this.$store.commit('learningPaths/setContents', response.data.contents)  

            this.loading = false
          } else {
            this.makeToast('danger', this.$t('Error'),this.$t('error_msg'));
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
      },
      async searchContents(){
        //// console.log("buscar contenidos con esta palabra:", this.searchword, "y con los criterios de filter options seleccionados")
        let data = {
          'user_id':  this.$store.getters['user/getUserData'].id,
          'q': this.searchword.toLowerCase(),
          'token': this.$store.getters['user/getUserData'].sso,
        }
        let filters = this.$store.getters['learningPaths/getContentFilterOptions']
        // console.log(filters)
        filters.forEach(filter_category => {
          let selected_options = []
          if(filter_category.name=='age'){

            let ages = []
            filter_category.options.map(op => {
                if(op.selected){
                    let ages_arr = op.id.split('-')
                    ages_arr.map(age => {
                        ages.push(parseInt(age))
                    })
                }
            })
            let min = Math.min(...ages)  
            let max = Math.max(...ages)
            
            selected_options = [min, max]

          } else {
            filter_category.options.forEach(opt => {
              if(opt.selected){
                selected_options.push(opt.id)
              }
            });
          }
          data[filter_category.name] = selected_options
        });
          //// console.log(data, "SEARCH")
          this.loading_contents = true

        await this.$http.post(fetchContentSearch, data).then( response => {
          // console.log(response)
          if(response.status == 200){
           if(response.data.length == 0){
            this.sortedContents = []
           } else {
            this.sortedContents = response.data
           }
            this.$store.commit('learningPaths/setFilteredContents', response.data)  
            this.loading_contents = false

          } else {
            this.makeToast('danger', this.$t('Error'),this.$t('error_msg'));
            this.loading_contents = false
          }
        }).catch(error => {
          this.loading_contents = false
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
      },
      makeToast(variant = null, tit ='',msg = '') {
        this.$bvToast.toast(msg, {
          title: tit,
          variant,
          solid: false,
        })
      },
      selectedContentsHierarchy(contents){
        if(contents.length == 0){
          this.sortedContents = []
        } else {
          this.sortedContents = contents
        }
        this.$store.commit('learningPaths/setFilteredContents', contents)
      },
      handleTemplateChange(){
        this.showSearchbar = !this.showSearchbar
      },
    }
}
</script>

<style lang="scss">
  @import '~@core/scss/base/pages/app-ecommerce.scss';

</style>